import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineTwitter
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import styled from "styled-components";

import myImg from "../../Assets/avatar.png";

const AvatarImg = styled.img`
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 20%);
  transition: all 0.5s ease, opacity 0.3s;
  border: 2px solid var(--primary-color);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #68187a;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
  }
  &:hover {
    color: #87209e;
    box-shadow: 0 0 20px #87209e;
  }
  &:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #801f95;
  }
`;

function Home2() {
  const jobStartDate = moment([2009, 6, 13]);
  const today = moment();

  // Experience calculations
  var years = today.diff(jobStartDate, "year");
  jobStartDate.add(years, "years");
  var months = today.diff(jobStartDate, "months");
  jobStartDate.add(months, "months");
  var days = today.diff(jobStartDate, "days");
  const experience = `${years} years, ${months} months and ${days} days`;
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 className="purple" style={{ fontSize: "2.6em" }}>
            Let me introduce myself
            </h1>
            <p className="home-about-body">
              My journey began with a humble computer and a curious mind, and since then, 
              I&apos;ve been conjuring up cutting-edge backend services, web technologies, 
              and products that make a real impact.
              <br /><br />
              With a skill set that&apos;s as versatile as a Swiss Army knife, I wield
              <i><b className="purple"> Java, Python, JavaScript, and ReactJS </b></i>
              like a pro, always pushing the boundaries of what&apos;s possible.
              <br /><br />
              When the opportunity arises, I dive headfirst into developing products that harness the power of 
              <i><b className="purple"> Node.js, Python, </b></i> and modern JavaScript libraries and frameworks, 
              including<i><b className="purple"> React.js and Next.js. </b></i>
              <br /><br />
              I&apos;m the master of my coding universe, and I&apos;m always on the lookout for the next big 
              adventure in tech.
              <br /><br />
              So, if you&apos;re ready to shake things up and build something truly epic, 
              let&apos;s join forces and create a digital revolution!
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <AvatarImg src={myImg} className="img-fluid avatar" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.rlchandani.dev"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.rlchandani.dev"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://linkedin.rlchandani.dev"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.rlchandani.dev"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
