import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiGit,
  DiJavascript1,
  DiNodejs,
  DiPython,
  DiReact
} from "react-icons/di";
import { FaJava } from "react-icons/fa";
import {
  SiAmazonaws,
  SiFirebase,
  SiPostgresql
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <FaJava size="96px" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython size="96px" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 size="96px" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs size="96px" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact size="96px" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws size="96px" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase size="96px" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql size="96px" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit size="96px" />
      </Col>
    </Row>
  );
}

export default Techstack;
