import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <b><span className="purple">Hi there! I&apos;m Rohit</span></b>
            <br/>
            <br/>
              A seasoned Senior Software Engineer at Amazon Retail,
              crafting innovative solutions to empower teams to communicate with customers in a seamless
              and transactional way. 
            <br/>
              With a passion for harnessing the power of AWS, I drive
              productivity and efficiency through its user-friendly interface, diverse toolset, and
              unparalleled reliability. 
            <br/>
            <br/>
              When I&apos;m not revolutionizing customer messaging, I dive
              into personal projects that ignite my creativity - from building websites and Telegram
              bots to automating financial portfolios and productivity tools.
            <br/>
              For low-budget projects,
              I combine the forces of AWS and Firebase to bring my ideas to life. 
            <br/>
            <br/>
              Throughout my career, I have had the privilege of working
              with esteemed organizations such as Infosys, Amdocs, Amazon
              and Meta, leveraging my foundational knowledge in
              Information Technology, which I acquired through my
              Bachelor&apos;s degree from Pune University.
              While coding remains a vital aspect of my professional
              life, I also find fulfillment in nurturing relationships
              with loved ones, exploring the great outdoors through
              hiking, and broadening my perspectives through travel.
            <br/>
            <br/>
              I look forward to collaborating with like-minded
              individuals and shaping the future of technology
              together.
          </p>
          <p style={{ color: "rgb(155 126 172)" }}>
            &quot;Strive to build things that make a difference!&quot;{" "}
          </p>
          <footer className="blockquote-footer">Rohit</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
